import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { Auth } from "aws-amplify";
import { bifrostAPIUrl } from "../midgard";

const customBackoff = async (attempt, maxRetries) => {
    //const attempts = Math.min(attempt, maxRetries);
    //const timeout = ~~((Math.random() + 0.4) * (300 << attempts)); // Force a positive int in the case we make this an option
    //const timeout = 2000 * attempts;
    // this used to wait 5 seconds, but we moved the wait to only do that if there is a 
    // retry-after header. But, some things don't have that header and we still want to wait
    // a couple seconds.
    await new Promise((resolve) => setTimeout((res) => resolve(res), 2000));
};

// custom base query to handled 202's and 401's
const customBaseQuery = retry(
    async (args, api, extraOptions) => {
        process.env.REACT_APP_ENV === "test" && console.log("DEBUG RTKQ args", args);
        try {
            let result = await fetchBaseQuery({
                baseUrl: bifrostAPIUrl,
                prepareHeaders: async (headers) => {
                    try {
                        const session = await Auth.currentSession();
                        headers.set("authorization", `Bearer ${ session?.getIdToken().getJwtToken() }`);
                    } catch (err) {
                        if (err !== "No current user") {
                            process.env.REACT_APP_ENV === "test" && console.log("DEBUG: caught an error inside prepareHeaders", err);
                        }
                    }
                    return headers;
                }
            })(args, api, extraOptions);
            process.env.REACT_APP_ENV === "test" && console.log("DEBUG RTKQ result", result);
            
            if (result.error?.originalStatus === 400 && result.error?.data.includes("Only one auth mechanism allowed")) {
                const fetchResponse = await fetch(result.meta.response.url);
                const data = await fetchResponse.json();
                result = {
                    data
                };
            } else if (result.meta.response?.headers && result.meta.response?.headers.get("retry-after") !== null) {
                const retryAfterSeconds = Math.max(2, Number.isInteger(parseInt(result.meta.response.headers.get("retry-after"))) ? parseInt(result.meta.response.headers.get("retry-after")) : 5) - 2;
                process.env.REACT_APP_ENV === "test" && console.log("Retrying after...", result.meta.response.headers.get("retry-after"), retryAfterSeconds);
                // we subtract 2 from the retry-after because every retry gets a 2 second delay in the custom backoff.
                await new Promise((resolve) => setTimeout((res) => resolve(res), retryAfterSeconds*1000));
                return {
                    error: "Retry limit reached."
                };
            } else if (result.error && result.error.status === 401) {
                // just adding this here separtely from the if (result.error) below
                // so that we can log some stuff if we ever get a 401 here...
                process.env.REACT_APP_ENV === "test" && console.log("401 Debug:", result);
                window.location.href = "/login"; // is this the correct way to do this?
                retry.fail("Unauthorized");
            } else if (result.error) {
                retry.fail(result.error);
            }
            return result;
        } catch (ex) {
            process.env.REACT_APP_ENV === "test" && console.log("Caught Error:", ex);
            retry.fail(ex.value?.error?.data?.message ?? ex.value?.error?.data?.Message ?? ex.toString());
        }
    },
    {
      maxRetries: 24,
      backoff: customBackoff
    }
);

export const midgardGet = async (url) => {
    const session = await Auth.currentSession();
    return fetch(`${ bifrostAPIUrl }/${ url }`, {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${ session?.getIdToken().getJwtToken() }`
        },
        redirect: "follow"
    });
};

export const midgardFetch = async (url, method, additionalHeaders, body) => {
    const session = await Auth.currentSession();
    return fetch(`${ bifrostAPIUrl }/${ url }`, {
        method,
        body,
        headers: {
            "Authorization": `Bearer ${ session?.getIdToken().getJwtToken() }`,
            ...additionalHeaders
        },
        redirect: "follow"
    });
};

export const mipApi = createApi({
    reducerPath: "mipApi",
    baseQuery: customBaseQuery,
    tagTypes: [
        "Process",
        "Resource",
        "Settings",
        "ExtendedSettings",
        "ManualProcess",
        "ProvisionedProcess",
        "ProvisionedResource",
        "ExternalData",
        "ExternalEntities",
        "ExternalSystems",
        "Files",
        "DataMaps",
        "EmailDistributionLists",
        "TaskResultActions",
        "Profile",
        "Customers",
        "ApiKeys",
        "OrderReservationWindows",
        "Users",
        "UrlSubstitutions",
        "Jobs"
    ],
    endpoints: (builder) => ({
        getSettings: builder.query({
            // we used to not cache settings because it was only called in the menu
            // which is never unloaded. Now that it is being used on configuration pages
            // and inside import/export data selector we will cache it like anyting else.
            keepUnusedDataFor: 86400,
            query: () => "settings",
            providesTags: ["Settings"],
            transformResponse: (response) => response?.Data ?? null,
        }),
        getExtendedSettings: builder.query({
            keepUnusedDataFor: 86400,
            query: () => "settings/extended",
            providesTags: ["ExtendedSettings"],
            transformResponse: (response) => response?.Data ?? null,
        }),
        getResources: builder.query({
            keepUnusedDataFor: 86400, // idea is to keep it forever unless invalidated by a mutation.
            query: () => "resources",
            providesTags: ["Resource"],
            transformResponse: (response) => response?.Data ?? null,
        }),
        getResource: builder.query({
            keepUnusedDataFor: 86400, // idea is to keep it forever unless invalidated by a mutation.
            query: (identifier) => `resources/${ identifier }`,
            providesTags: ["Resource"],
            transformResponse: (response) => response?.Data ?? null,
        }),
        getProvisionedResources: builder.query({
            keepUnusedDataFor: 86400, // idea is to keep it forever unless invalidated by a mutation.
            query: () => "provisionedresources",
            providesTags: ["ProvisionedResource"],
            transformResponse: (response) => response?.Data ?? null,
        }),
        getProvisionedResource: builder.query({
            keepUnusedDataFor: 86400, // idea is to keep it forever unless invalidated by a mutation.
            query: (identifier) => `provisionedresources/${ identifier }`,
            providesTags: ["ProvisionedResource"],
            transformResponse: (response) => response?.Data ?? null,
        }),
        provisionResource: builder.mutation({
            query: (body) => ({
                    url: "provisionedresources",
                    method: "POST",
                    body // fetchBaseQuery automatically adds `content-type: application/json` to the Headers and calls `JSON.stringify(body)`
                }),
            // When you provision/deprovision a resource the list of ProvisionedResources changes,
            // so does Resources if the one changed was a "onlyOne". Also, the available processes
            // will change since proceses are tied to their resource.
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["Resource", "ProvisionedResource", "Process", "ExternalData"];
                }
                return [];
            }
        }),
        updateResource: builder.mutation({
            query: (data) => {
                const {id, body} = data;
                return {
                    url: `provisionedresources/${ id }`,
                    method: "PUT",
                    body // fetchBaseQuery automatically adds `content-type: application/json` to the Headers and calls `JSON.stringify(body)`
                };
            },
            // When you update a resource the list of ProvisionedResources changes,
            // but updating a process should not change Resources, Processes, or ExternalDta
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["ProvisionedResource"];
                }
                return [];
            }
        }),
        deprovisionResource: builder.mutation({
            query: (id) => ({
                    url: `provisionedresources/${ id }`,
                    method: "DELETE"
                }),
            // When you provision/deprovision a resource the list of ProvisionedResources changes,
            // so does Resources if the one changed was a "onlyOne". Also, the available processes
            // will change since proceses are tied to their resource.
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["Resource", "ProvisionedResource", "Process"];
                }
                return [];
            }
        }),
        getManualProcesses: builder.query({
            keepUnusedDataFor: 86400, // idea is to keep it forever unless invalidated by a mutation.
            query: () => "provisionedprocesses/manual",
            providesTags: ["ManualProcess"],
            transformResponse: (response) => response?.Data ?? null,
        }),
        getManualProcess: builder.query({
            keepUnusedDataFor: 86400, // idea is to keep it forever unless invalidated by a mutation.
            query: (identifier) => `provisionedprocesses/manual/${ identifier }`,
            providesTags: ["ManualProcess"],
            transformResponse: (response) => response?.Data ?? null,
        }),
        deleteManualProcess: builder.mutation({
            query: (id) => ({
                    url: `provisionedprocesses/manual/${ id }`,
                    method: "DELETE"
                }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["ManualProcess", "Jobs"];
                }
                return [];
            }
        }),
        updateManualProcess: builder.mutation({
            query: (data) => ({
                url: `/provisionedprocesses/manual/${ data.identifier }`,
                method: "PATCH",
                body: data.body
            }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["ManualProcess"];
                }
                return [];
            }
        }),
        getProcesses: builder.query({
            keepUnusedDataFor: 86400, // idea is to keep it forever unless invalidated by a mutation.
            query: () => "processes",
            providesTags: ["Process"],
            transformResponse: (response) => response?.Data ?? null,
        }),
        getProcess: builder.query({
            keepUnusedDataFor: 86400, // idea is to keep it forever unless invalidated by a mutation.
            query: (identifier) => `processes/${ identifier }`,
            providesTags: ["Process"],
            transformResponse: (response) => response?.Data ?? null,
        }),
        getProvisionedProcesses: builder.query({
            keepUnusedDataFor: 86400, // idea is to keep it forever unless invalidated by a mutation.
            query: () => "provisionedprocesses",
            providesTags: ["ProvisionedProcess"],
            transformResponse: (response) => response?.Data ?? null,
        }),
        getProvisionedProcess: builder.query({
            keepUnusedDataFor: 86400, // idea is to keep it forever unless invalidated by a mutation.
            query: (identifier) => `provisionedprocesses/${ identifier }`,
            providesTags: ["ProvisionedProcess"],
            transformResponse: (response) => response?.Data ?? null,
        }),
        provisionProcess: builder.mutation({
            query: (body) => ({
                    url: "provisionedprocesses",
                    method: "POST",
                    body // fetchBaseQuery automatically adds `content-type: application/json` to the Headers and calls `JSON.stringify(body)`
                }),
            // When you provision/deprovision a process only the provisionedProcess list changes.
            // We don't currenly have any "onlyOne" processes so the process list is only changed
            // when resources are provisioned/deprovisioned.
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["ProvisionedProcess", "ExternalData"];
                }
                return [];
            }
        }),
        updateProcess: builder.mutation({
            query: (data) => {
                const {id, body} = data;
                return {
                    url: `provisionedprocesses/${ id }`,
                    method: "PUT",
                    body // fetchBaseQuery automatically adds `content-type: application/json` to the Headers and calls `JSON.stringify(body)`
                };
            },
            // When you provision/deprovision a process only the provisionedProcess list changes.
            // We don't currenly have any "onlyOne" processes so the process list is only changed
            // when resources are provisioned/deprovisioned.
            // function signature: (results, error, args/data)
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["ProvisionedProcess", "ExternalData"];
                }
                return [];
            }
        }),
        deprovisionProcess: builder.mutation({
            query: (id) => ({
                    url: `provisionedprocesses/${ id }`,
                    method: "DELETE"
                }),
            // When you provision/deprovision a process only the provisionedProcess list changes.
            // We don't currenly have any "onlyOne" processes so the process list is only changed
            // when resources are provisioned/deprovisioned.
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["ProvisionedProcess"];
                }
                return [];
            }
        }),
        deployProcess: builder.mutation({
            query: (id) => ({
                    url: `provisionedprocesses/${ id }/deploy`,
                    method: "POST"
                }),
            // When you provision/deprovision a process only the provisionedProcess list changes.
            // We don't currenly have any "onlyOne" processes so the process list is only changed
            // when resources are provisioned/deprovisioned.
            // Settings contain ApiMaps which can be changed when processes are provisioned.
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["ProvisionedProcess", "Settings"];
                }
                return [];
            }
        }),
        undeployProcess: builder.mutation({
            query: (data) => ({
                    url: `provisionedprocesses/${ data.identifier }/deploy${ data.killRunningTasks ? "?kill=true" : "" }`,
                    method: "DELETE"
                }),
            // When you provision/deprovision a process only the provisionedProcess list changes.
            // We don't currenly have any "onlyOne" processes so the process list is only changed
            // when resources are provisioned/deprovisioned.
            // Settings contain ApiMaps which can be changed when processes are deprovisioned.
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["ExternalData", "ProvisionedProcess", "Settings"];
                }
                return [];
            }
        }),
        queueProcess: builder.mutation({
            query: (data) => ({
                    url: `provisionedprocesses/${ data.id }/queue`,
                    method: "POST",
                    body: data.body ? data.body : undefined
                })
        }),
        queueManualProcess: builder.mutation({
            query: (data) => ({
                url: `provisionedprocesses/manual/${ data.id }/queue`,
                method: "POST",
                body: data.body ? data.body : undefined
            }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["Jobs"];
                }
                return [];
            }
        }),
        createManualProcess: builder.mutation({
            query: (body) => ({
                    url: "provisionedprocesses/manual",
                    method: "POST",
                    body
            }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["ManualProcess"];
                }
                return [];
            }
        }),
        getExternalData: builder.query({
            // reduced this to 5m (300) from 1d (86400) to just generally reduce noise
            keepUnusedDataFor: 300,
            query: (url) => url,
            // for providesTags, the first discard is the results, 
            // the second is unknown/undefined
            // in this case ID is the URL of the externalData API we are hitting
            // The POST/PUT to Processes also invalidates all externalData tags
            // invalidating will cause any still in use to refetch and any not in use
            // to become undefined again.
            // this uses a fancy es6 function that returns an array and the fancy
            // es6 object shortcut for {id: id}
            providesTags: (_, __, id) => [{ type: "ExternalData", id }],
            transformResponse: (response, meta) => response?.Data ?? null,
        }),
        getDataMaps: builder.query({
            keepUnusedDataFor: 86400, // idea is to keep it forever unless invalidated by a mutation.
            query: () => "settings/datamaps",
            providesTags: ["DataMaps"],
            // transformResponse: (response) => {
            //     return response?.Data ?? null
            // },
        }),
        createDataMap: builder.mutation({
            query: (body) => ({
                    url: "settings/datamaps",
                    method: "POST",
                    body // fetchBaseQuery automatically adds `content-type: application/json` to the Headers and calls `JSON.stringify(body)`
                }),
            // When you provision/deprovision a resource the list of ProvisionedResources changes,
            // so does Resources if the one changed was a "onlyOne". Also, the available processes
            // will change since proceses are tied to their resource.
            // Settings contain a data map list so when data maps are added/modified/deleted settings needs 
            // to be invalidated.
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["DataMaps", "Settings"];
                }
                return [];
            }
        }),
        updateDataMap: builder.mutation({
            query: (data) => ({
                    url: `settings/datamaps/${ data.id }`,
                    method: "PUT",
                    body: data.body
                }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["DataMaps", "Settings"];
                }
                return [];
            }
        }),
        deleteDataMap: builder.mutation({
            query: (id) => ({
                    url: `settings/datamaps/${ id }`,
                    method: "DELETE"
                }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["DataMaps", "Settings"];
                }
                return [];
            }
        }),
        getEmailDistributionList: builder.query({
            keepUnusedDataFor: 86400, // idea is to keep it forever unless invalidated by a mutation.
            query: (identifier) => `settings/emaildistributionlists/${ identifier }`,
            providesTags: (result, error, id) => [{ type: "EmailDistributionLists", id }],
            transformResponse: (response) => response?.Data ?? null,
        }),
        getEmailDistributionLists: builder.query({
            keepUnusedDataFor: 86400, // idea is to keep it forever unless invalidated by a mutation.
            query: () => "settings/emaildistributionlists",
            providesTags: ["EmailDistributionLists"],
            // transformResponse: (response) => {
            //     return response?.Data ?? null
            // },
        }),
        createEmailDistributionList: builder.mutation({
            query: (body) => ({
                    url: "settings/emaildistributionlists",
                    method: "POST",
                    body // fetchBaseQuery automatically adds `content-type: application/json` to the Headers and calls `JSON.stringify(body)`
                }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["EmailDistributionLists"];
                }
                return [];
            }
        }),
        updateEmailDistributionList: builder.mutation({
            query: (data) => ({
                    url: `settings/emaildistributionlists/${ data.id }`,
                    method: "PUT",
                    body: data.body
                }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["EmailDistributionLists"];
                }
                return [];
            }
        }),
        deleteEmailDistributionList: builder.mutation({
            query: (id) => ({
                    url: `settings/emaildistributionlists/${ id }`,
                    method: "DELETE"
                }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["EmailDistributionLists"];
                }
                return [];
            }
        }),
        getTaskResultAction: builder.query({
            keepUnusedDataFor: 86400, // idea is to keep it forever unless invalidated by a mutation.
            query: (identifier) => `settings/taskresultactions/${ identifier }`,
            providesTags: (result, error, id) => [{ type: "TaskResultActions", id }],
            transformResponse: (response) => response?.Data ?? null,
        }),
        getTaskResultActions: builder.query({
            keepUnusedDataFor: 86400, // idea is to keep it forever unless invalidated by a mutation.
            query: () => "settings/taskresultactions",
            providesTags: ["TaskResultActions"],
            // transformResponse: (response) => {
            //     return response?.Data ?? null
            // },
        }),
        createTaskResultAction: builder.mutation({
            query: (body) => ({
                    url: "settings/taskresultactions",
                    method: "POST",
                    body // fetchBaseQuery automatically adds `content-type: application/json` to the Headers and calls `JSON.stringify(body)`
                }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["TaskResultActions"];
                }
                return [];
            }
        }),
        updateTaskResultAction: builder.mutation({
            query: (data) => ({
                    url: `settings/taskresultactions/${ data.id }`,
                    method: "PUT",
                    body: data.body
                }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["TaskResultActions"];
                }
                return [];
            }
        }),
        deleteTaskResultAction: builder.mutation({
            query: (id) => ({
                    url: `settings/taskresultactions/${ id }`,
                    method: "DELETE"
                }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["TaskResultActions"];
                }
                return [];
            }
        }),
        getOrderReservationWindows: builder.query({
            keepUnusedDataFor: 86400, // idea is to keep it forever unless invalidated by a mutation.
            query: () => "settings/orderreservationwindows",
            providesTags: ["OrderReservationWindows"],
            // transformResponse: (response) => {
            //     return response?.Data ?? null
            // },
        }),
        createOrderReservationWindow: builder.mutation({
            query: (body) => ({
                    url: "settings/orderreservationwindows",
                    method: "POST",
                    body // fetchBaseQuery automatically adds `content-type: application/json` to the Headers and calls `JSON.stringify(body)`
                }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["OrderReservationWindows"];
                }
                return [];
            }
        }),
        updateOrderReservationWindow: builder.mutation({
            query: (data) => ({
                    url: `settings/orderreservationwindows/${ data.id }`,
                    method: "PUT",
                    body: data.body
                }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["OrderReservationWindows"];
                }
                return [];
            }
        }),
        deleteOrderReservationWindow: builder.mutation({
            query: (id) => ({
                    url: `settings/orderreservationwindows/${ id }`,
                    method: "DELETE"
                }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["OrderReservationWindows"];
                }
                return [];
            }
        }),
        getProfile: builder.query({
            keepUnusedDataFor: 86400, // idea is to keep it forever unless invalidated by a mutation.
            query: () => "/profile",
            providesTags: ["Profile"],
        }),
        updateCustomerIdentifier: builder.mutation({
            query: (body) => ({
                    url: "/profile",
                    method: "PATCH",
                    body
                }),
            // Changing your profile resets EVERYTHING
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return [
                        "Process",
                        "Resource",
                        "Settings",
                        "ExtendedSettings",
                        "ProvisionedProcess",
                        "ProvisionedResource",
                        "ExternalData",
                        "DataMaps",
                        "Profile",
                        "Customers",
                        "OrderReservationWindows",
                        "Users"
                    ];
                }
                return [];
            }
        }),
        getCustomers: builder.query({
            keepUnusedDataFor: 86400, // idea is to keep it forever unless invalidated by a mutation.
            query: () => "/customers",
            providesTags: ["Customers"],
        }),
        addCustomer: builder.mutation({
            query: (body) => ({
                    url: "/customers",
                    method: "POST",
                    body // fetchBaseQuery automatically adds `content-type: application/json` to the Headers and calls `JSON.stringify(body)`
                }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["Customers"];
                }
                return [];
            }
        }),
        updateCustomer: builder.mutation({
            query: (data) => ({
                url: `/customers/${ data.identifier }`,
                method: "PATCH",
                body: data.body
            }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return [
                        "Customers",
                        "Settings",
                        "ExtendedSettings"
                    ];
                }
                return [];
            }
        }),
        getUsers: builder.query({
            keepUnusedDataFor: 86400, // idea is to keep it forever unless invalidated by a mutation.
            query: () => "/admin/users",
            providesTags: ["Users"],
        }),
        getUser: builder.query({
            keepUnusedDataFor: 86400, // idea is to keep it forever unless invalidated by a mutation.
            query: (username) => `/admin/users/${ username }`,
            providesTags: (result, error, id) => [{ type: "Users", id }]
        }),
        createUser: builder.mutation({
            query: (body) => ({
                url: "/admin/users",
                method: "POST",
                body
            }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["Users"];
                }
                return [];
            }
        }),
        updateUser: builder.mutation({
            query: (data) => ({
                url: `/admin/users/${ data.username }`,
                method: "PUT",
                body: data.body
            }),
            invalidatesTags: (_, error, data) => {
                if (!error) {
                    return ["Users", {Type: "Users", id: data.username }];
                }
                return [];
            }
        }),
        toggleUser: builder.mutation({
            query: (data) => ({
                url: `/admin/users/${ data.username }`,
                method: "PATCH",
                body: {
                    Enabled: data.enabled
                }
            }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["Users"];
                }
                return [];
            }
        }),
        resendUserConfirmation: builder.mutation({
            query: (username) => ({
                url: `/admin/users/${ username }/resendconfirmation`,
                method: "PUT"
            }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["Users"];
                }
                return [];
            }
        }),
        updateGeneralSettings: builder.mutation({
            query: (body) => ({
                    url: "settings",
                    method: "PATCH",
                    body
                }),
            // this is how we handle adding licenses so it needs to invalidate
            // processes and resources
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["Settings", "Process", "Resource"];
                }
                return [];
            }
        }),
        updateExtendedSettings: builder.mutation({
            query: (body) => ({
                    url: "settings/extended",
                    method: "PATCH",
                    body
                }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["ExtendedSettings"];
                }
                return [];
            }
        }),
        getApiKeys: builder.query({
            keepUnusedDataFor: 86400, // idea is to keep it forever unless invalidated by a mutation.
            query: () => "settings/apikeys?includeUsagePlan=true",
            providesTags: ["ApiKeys"]
        }),
        getUsagePlans: builder.query({
            keepUnusedDataFor: 86400, // idea is to keep it forever unless invalidated by a mutation.
            query: () => "settings/apikeys/usageplans",
        }),
        getApiKeyValue: builder.mutation({
            keepUnusedDataFor: 0,
            query: (apiKey) => ({
                    url: `settings/apikeys/${  apiKey  }?includeValue=true`,
                    method: "GET"
                }),
        }),
        addApiKey: builder.mutation({
            query: (body) => ({
                    url: "settings/apikeys",
                    method: "POST",
                    body // fetchBaseQuery automatically adds `content-type: application/json` to the Headers and calls `JSON.stringify(body)`
                }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["ApiKeys"];
                }
                return [];
            }
        }),
        deleteApiKey: builder.mutation({
            query: (id) => ({
                    url: `settings/apikeys/${ id }`,
                    method: "DELETE"
                }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["ApiKeys"];
                }
                return [];
            }
        }),
        updateApiKey: builder.mutation({
            query: (data) => ({
                    url: `settings/apikeys/${ data.id }`,
                    method: "PUT",
                    body: data.body
                }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["ApiKeys"];
                }
                return [];
            }
        }),
        toggleApiKey: builder.mutation({
            query: (data) => ({
                    url: `settings/apikeys/${ data.id }`,
                    method: "PATCH",
                    body: data.body
                }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["ApiKeys"];
                }
                return [];
            }
        }),
        convertToJson: builder.mutation({
            query: (data) => ({
                    url: data.url,
                    method: "POST",
                    body: data.body
                }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["ApiKeys"];
                }
                return [];
            }
        }),
        getKillSettings: builder.query({
            keepUnusedDataFor: 0, // This should be re-retrieved each time to be safe.
            query: () => "settings/kill",
        }),
        createKillTCIorKillAll: builder.mutation({
            query: (identifier) => ({
                url: identifier ? `settings/kill/${ identifier }` : "settings/kill",
                method: "POST"
            })
        }),
        // Identifier here could be undefined (remove kill all file), a Process ID or a TCI
        // the endpoint removes them from both Process and TCI list since it should only bein one.
        deleteKillSettings: builder.mutation({
            query: (identifier) => ({
                url: identifier ? `settings/kill/${ identifier }` : "settings/kill",
                method: "DELETE"
            })
        }),
        getLogs: builder.query({
            query: (args) => {
                const { globalLogs, lambdaRequestIdentifier, timestampRangeStart, orderByDescending } = args;
                const url = lambdaRequestIdentifier !== ""
                    ? `logs?lambdaRequestIdentifier=${ lambdaRequestIdentifier }`
                    : globalLogs
                        ? "logs?globalLogs=true"
                        : "logs";
                return {
                    url,
                    params: {
                        timestampRangeStart,
                        orderByDescending,
                        //limit: 5,
                        extend: true
                    }
                };
            }
        }),
        getProcessTrackingFiles: builder.query({
            keepUnusedDataFor: 86400, // idea is to keep it forever unless invalidated by a mutation.
            query: (args) => {
                const { provisionedProcessIdentifier, taskIdentifier, taskChainInstance, metadataFilters } = args;
                const urlParameters = [];
                if (provisionedProcessIdentifier !== "") {
                    urlParameters.push(`provisionedProcessIdentifier=${ encodeURIComponent(provisionedProcessIdentifier) }`);
                }
                if (provisionedProcessIdentifier !== "" && taskIdentifier !== "") {
                    urlParameters.push(`taskIdentifier=${ encodeURIComponent(taskIdentifier) }`);
                }
                if (taskChainInstance !== "") {
                    urlParameters.push(`taskChainInstance=${ encodeURIComponent(taskChainInstance) }`);
                }
                if (Array.isArray(metadataFilters)) {
                    for (const filter of metadataFilters) {
                        urlParameters.push(`${ filter.metadataKey }=${ encodeURIComponent(filter.value) }`);
                    }
                }
                return {
                    url: "processtracking",
                    params: urlParameters.join("&")
                };
            }
        }),
        seedProcessTrackingMetadata: builder.mutation({
            query: (data) => {
                const urlParameters = [];
                if (data.provisionedProcessIdentifier !== "") {
                    urlParameters.push(`provisionedProcessIdentifier=${ encodeURIComponent(data.provisionedProcessIdentifier) }`);
                }
                return {
                    url: `processtracking/metadata/${ data.metadataKey }`,
                    params: urlParameters.join("&"),
                    method: "PUT"
                };
            }
        }),
        getProcessTrackingFile: builder.query({
            keepUnusedDataFor: 86400, // idea is to keep it forever unless invalidated by a mutation.
            query: (filename) => ({
                url: `processtracking/${ filename }`,
            })
        }),
        getFilesByPrefix: builder.query({
            keepUnusedDataFor: 0, // idea is to have this refresh on each load
            query: (prefix) => ({
                url: `files${ prefix !== "" ? `?objectKeyPrefix=${ encodeURIComponent(prefix) }` : "" }`,
            }),
            providesTags: ["Files"],
        }),
        deleteFile: builder.mutation({
            query: (filename) => ({
                url: `files/${ encodeURIComponent(filename) }`,
                method: "DELETE"
            }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["Files"];
                }
                return [];
            }
        }),
        deleteFolder: builder.mutation({
            query: (filename) => ({
                url: `files?folder=${ encodeURIComponent(filename) }`,
                method: "DELETE"
            }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["Files"];
                }
                return [];
            }
        }),
        createFile: builder.mutation({
            query: (body) => ({
                url: "files",
                method: "POST",
                body
            }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["Files"];
                }
                return [];
            }
        }),
        updateFile: builder.mutation({
            query: (body) => ({
                url: `file/${ encodeURIComponent(body.ObjectKey) }`,
                method: "PUT",
                body
            }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["Files"];
                }
                return [];
            }
        }),
        getUrlSubstitutions: builder.query({
            keepUnusedDataFor: 86400, // idea is to keep it forever unless invalidated by a mutation.
            query: () => "/settings/urlsubstitutions",
            providesTags: ["UrlSubstitutions"],
        }),
        createUrlSubstitution: builder.mutation({
            query: (body) => ({
                url: "/settings/urlsubstitutions",
                method: "POST",
                body
            }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["UrlSubstitutions"];
                }
                return [];
            }
        }),
        deleteUrlSubstitution: builder.mutation({
            query: (id) => ({
                url: `/settings/urlsubstitutions/${ id }`,
                method: "DELETE"
            }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["UrlSubstitutions"];
                }
                return [];
            }
        }),
        extendUrlSubstitution: builder.mutation({
            query: (id) => ({
                url: `/settings/urlsubstitutions/${ id }`,
                method: "PATCH"
            }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["UrlSubstitutions"];
                }
                return [];
            }
        }),
        getJobResults: builder.query({
            keepUnusedDataFor: 0,
            query: (jobIdentifier) => `/jobs/${ jobIdentifier }`
        }),
        getJobHistory: builder.query({
            keepUnusedDataFor: 3600,
            query: () => "/jobs",
            providesTags: ["Jobs"],
        }),
        deleteRNACache: builder.mutation({
            query: (id) => ({
                url: `resources/rnasoap/cache/${ id }`,
                method: "DELETE"
            })
        }),
        getExternalEntitiesSystem: builder.query({
            keepUnusedDataFor: 86400,
            query: (id) => `externalentities/systems/${ id }`,
            providesTags: (result, error, id) => [{ type: "ExternalSystems", id }],
            transformResponse: (response) => response?.Data ?? null,
        }),
        getExternalEntitiesSystems: builder.query({
            keepUnusedDataFor: 86400,
            query: () => "externalentities/systems",
            providesTags: ["ExternalSystems"],
            transformResponse: (response) => response?.Data ?? null,
        }),
        createExternalEntitiesSystems: builder.mutation({
            query: (body) => ({
                url: "externalentities/systems",
                method: "POST",
                body
            }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["ExternalSystems"];
                }
                return [];
            }
        }),
        updateExternalEntitiesSystems: builder.mutation({
            query: (data) => ({
                    url: `externalentities/systems/${ data.id }`,
                    method: "PUT",
                    body: data.body
                }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["ExternalSystems"];
                }
                return [];
            }
        }),
        deleteExternalEntitiesSystems: builder.mutation({
            query: (id) => ({
                url: `externalentities/systems/${ id }`,
                method: "DELETE"
            }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["ExternalSystems"];
                }
                return [];
            }
        }),
        getExternalEntity: builder.query({
            keepUnusedDataFor: 86400,
            query: (id) => `externalentities/${ id }`,
            providesTags: (result, error, id) => [{ type: "ExternalEntities", id }],
            transformResponse: (response) => response?.Data ?? null,
        }),
        getExternalEntities: builder.query({
            keepUnusedDataFor: 86400,
            query: (systemId) => `externalentities?externalEntitySystemIdentifier=${ systemId }`,
            providesTags: ["ExternalEntities"],
            transformResponse: (response) => response?.Data ?? null,
        }),
        createExternalEntities: builder.mutation({
            query: (body) => ({
                url: "externalentities",
                method: "POST",
                body
            }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["ExternalEntities"];
                }
                return [];
            }
        }),
        updateExternalEntities: builder.mutation({
            query: (data) => ({
                url: `externalentities/${ data.id }`,
                method: "PUT",
                body: data.body
            }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["ExternalEntities"];
                }
                return [];
            }
        }),
        deleteExternalEntities: builder.mutation({
            query: (id) => ({
                url: `externalentities/${ id }`,
                method: "DELETE"
            }),
            invalidatesTags: (_, error, __) => {
                if (!error) {
                    return ["ExternalEntities"];
                }
                return [];
            }
        }),
    })
});

export const { 
    useGetSettingsQuery,
    useGetExtendedSettingsQuery,
    useGetResourcesQuery,
    useGetResourceQuery,
    useGetProvisionedResourcesQuery,
    useGetProvisionedResourceQuery,
    useProvisionResourceMutation,
    useDeprovisionResourceMutation,
    useUpdateResourceMutation,
    useCreateManualProcessMutation,
    useQueueManualProcessMutation,
    useUpdateManualProcessMutation,
    useDeleteManualProcessMutation,
    useGetManualProcessesQuery,
    useGetManualProcessQuery,
    useGetProcessesQuery,
    useGetProcessQuery,
    useGetProvisionedProcessesQuery,
    useGetProvisionedProcessQuery,
    useProvisionProcessMutation,
    useDeprovisionProcessMutation,
    useUpdateProcessMutation,
    useDeployProcessMutation,
    useUndeployProcessMutation,
    useQueueProcessMutation,
    useGetExternalDataQuery,
    useGetDataMapsQuery,
    useCreateDataMapMutation,
    useUpdateDataMapMutation,
    useDeleteDataMapMutation,
    useGetEmailDistributionListQuery,
    useGetEmailDistributionListsQuery,
    useCreateEmailDistributionListMutation,
    useUpdateEmailDistributionListMutation,
    useDeleteEmailDistributionListMutation,
    useGetTaskResultActionQuery,
    useGetTaskResultActionsQuery,
    useCreateTaskResultActionMutation,
    useUpdateTaskResultActionMutation,
    useDeleteTaskResultActionMutation,
    useGetProfileQuery,
    useUpdateCustomerIdentifierMutation,
    useGetCustomersQuery,
    useAddCustomerMutation,
    useUpdateCustomerMutation,
    usePrefetch,
    useUpdateGeneralSettingsMutation,
    useUpdateExtendedSettingsMutation,
    useGetApiKeysQuery,
    useGetApiKeyValueMutation,
    useAddApiKeyMutation,
    useGetUsagePlansQuery,
    useDeleteApiKeyMutation,
    useToggleApiKeyMutation,
    useUpdateApiKeyMutation,
    useGetExternalEntitiesSystemsQuery,
    useGetExternalEntitiesSystemQuery,
    useCreateExternalEntitiesSystemsMutation,
    useUpdateExternalEntitiesSystemsMutation,
    useDeleteExternalEntitiesSystemsMutation,
    useGetExternalEntitiesQuery,
    useGetExternalEntityQuery,
    useCreateExternalEntitiesMutation,
    useUpdateExternalEntitiesMutation,
    useDeleteExternalEntitiesMutation,
    useConvertToJsonMutation,
    useGetKillSettingsQuery,
    useCreateKillTCIorKillAllMutation,
    useDeleteKillSettingsMutation,
    useGetOrderReservationWindowsQuery,
    useCreateOrderReservationWindowMutation,
    useUpdateOrderReservationWindowMutation,
    useDeleteOrderReservationWindowMutation,
    useGetUsersQuery,
    useGetUserQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useToggleUserMutation,
    useResendUserConfirmationMutation,
    useGetUrlSubstitutionsQuery,
    useCreateUrlSubstitutionMutation,
    useDeleteUrlSubstitutionMutation,
    useExtendUrlSubstitutionMutation,
    useSeedProcessTrackingMetadataMutation,
    useGetProcessTrackingFileQuery,
    useDeleteRNACacheMutation,
    useGetJobResultsQuery,
    useGetJobHistoryQuery,
    useGetFilesByPrefixQuery,
    useDeleteFileMutation,
    useDeleteFolderMutation,
    useCreateFileMutation,
    useUpdateFileMutation,
} = mipApi;